'use client';

import Image from 'next/image';
import { forwardRef } from 'react';
import ArrowLeft from '../../../../public/images/Common/ArrowLeft.png';
import ArrowRight from '../../../../public/images/Common/ArrowRight.png';

interface IClientButtonProps {
  position: 'left' | 'right';
  onClick: () => void; // Tốt hơn là định nghĩa kiểu cụ thể thay vì any
}

const ClientButton = forwardRef<HTMLImageElement, IClientButtonProps>((props, ref) => {
  const { position = 'left', onClick } = props;

  return (
    <Image
      ref={ref}
      onClick={onClick}
      width={48}
      height={48}
      className='!size-12 cursor-pointer object-contain'
      alt={position === 'left' ? 'arrow left' : 'arrow right'}
      src={position === 'left' ? ArrowLeft : ArrowRight}
    />
  );
});

// Thêm displayName cho component (tốt cho debugging)
ClientButton.displayName = 'ClientButton';

export default ClientButton;
