export enum RoleKey {
  ADMINISTRATOR = 'ADMINISTRATOR',
  SALON_OWNER = 'SALON_OWNER',
  MANAGER = 'MANAGER',
  STAFF = 'STAFF',
  CUSTOMER = 'CUSTOMER',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ORTHER = 'ORTHER',
}

export enum Brand {
  VISA = 'VISA',
  MC = 'MC',
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  DINERS_CLUB = 'DINERS_CLUB',
  JCB = 'JCB',
}

export enum Ecomind {
  ECOM = 'ecom',
  MOTO = 'moto ',
}

export enum LoginRoleKey {
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
}
