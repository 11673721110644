import Image from 'next/image';
import Staff2 from '../../../../../../public/images/HeraHeadSpa-154 1.png';
import Staff1 from '../../../../../../public/images/HeraHeadSpa-56 1.png';
import PhoneCall from '../../../../../../public/images/phone-call.png';

const Consultation = () => {
  return (
    <div className='mx-auto mb-10 mt-10 flex max-h-[170px] min-h-[120px] w-full rounded-full !border-none bg-opacity-85 bg-contact-bg bg-cover bg-center bg-no-repeat !outline !outline-[#FCDCAB] sm:mt-20'>
      <div className='flex w-full items-center justify-between rounded-full !border-none bg-[linear-gradient(90deg,_rgba(255,_255,_255,_0.9)_0%,_rgba(255,_255,_255,_0.45)_100%)] px-4 sm:px-16'>
        <div className='relative flex justify-start gap-6'>
          <div className='relative flex size-[56px] items-center justify-center rounded-full bg-zelene-red'>
            <span className='absolute inline-flex h-full w-full animate-customPing cursor-pointer items-center justify-center rounded-full opacity-75'>
              <a href={`tel:4692071863`} target='_blank' rel='noopener noreferrer'>
                <Image src={PhoneCall} alt='phone call' width={72} height={72} className='w-auto object-contain' />
              </a>
            </span>
          </div>

          <span className='max-w-[242px] font-[wise] text-[22px] font-semibold leading-8 text-zelene-red sm:text-[24px]'>
            Consultation With Our Experts
          </span>
        </div>

        <div className='flex items-end justify-end gap-4 self-end *:z-10'>
          <Image
            src={Staff1}
            alt='phone call'
            width={157}
            height={200}
            className='hidden h-[200px] min-w-[157px] object-contain lg:block'
          />

          <Image
            src={Staff2}
            alt='phone call'
            width={203}
            height={208}
            className='-ml-6 hidden h-[208px] w-[203px] object-contain lg:block'
          />
        </div>
      </div>
    </div>
  );
};

export default Consultation;
