export const UserRoleKey = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  SALON_OWNER: 'SALON_OWNER',
  MANAGER: 'MANAGER',
  STAFF: 'STAFF',
  CUSTOMER: 'CUSTOMER',
};

export const StatusBookingKey = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  ARRIVED : 'ARRIVED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export enum PaymentMethod {
  CREDIT = 'CREDIT',
  CASH = 'CASH',
  DEBIT = 'DEBIT',
  VISA = 'VISA',
  MASTER = 'MASTER',
  AMEX = 'AMEX',
  PAYMENT_APP = 'PAYMENT_APP',
  MEMBER_CARD = 'MEMBER_CARD',
  GIFT_CARD = 'GIFT_CARD',
  CLOVER = 'CLOVER',
  MERCHANT_TERMINAL = 'MERCHANT_TERMINAL',
  MERCHANT_EXTERNAL = 'MERCHANT_EXTERNAL',
  CARD_ON_FILE = 'CARD_ON_FILE',
}

export enum PaymentStatus {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
}

export enum EnumGender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum PaymentGateWay {
  CLOVERTERMINAL = 'CLOVERTERMINAL',
  CLOVERECOMMERCE = 'CLOVERECOMMERCE',
  EXTERNAL = 'EXTERNAL',
}
