import Image from 'next/image';
import Link from 'next/link';
import RedStarSVG from '../../../../../public/svgs/RedStarSVG.svg';

const items = [
  { label: 'Book Appointment', url: '/booking' },
  { label: 'Head Spa', url: '/services?category=0' },
  { label: 'Facial', url: '/services?category=2' },
  { label: 'Gift Card', url: '/gift' },
  { label: 'Combo', url: '/services?category=2' },
  { label: 'Book Appointment', url: '/booking' },
  // { label: 'Products', url: '#' },
  { label: 'Head Spa', url: '/services?category=0' },
  { label: 'Facial', url: '/services?category=2' },
  { label: 'Gift Card', url: '/gift' },
  { label: 'Combo', url: '/services?category=1' },
  { label: 'Book Appointment', url: '/booking' },
  { label: 'Head Spa', url: '/services?category=0' },
  // { label: 'Products', url: '#' },
];

interface AutoSlideProps {
  backgroundColor?: string; // Optional background color prop
}

const AutoSlide: React.FC<AutoSlideProps> = ({ backgroundColor }) => {
  return (
    <div id='slider' className={`relative m-auto h-12 w-full overflow-hidden ${backgroundColor || 'bg-zelene-yellow'}`}>
      <div id='slide-strack' className='flex w-[5120px] animate-scroll hover:[animation-play-state:paused]'>
        {[...Array(2)].flatMap((_, loopIndex) =>
          items.map((item, itemIndex) => (
            <div
              id='slide'
              key={`slide-${loopIndex}-${item.url}-${itemIndex}`}
              className='flex w-[320px] items-center justify-around'
            >
              <Link
                href={item.url}
                className='flex flex-1 cursor-pointer justify-center font-[wise] text-base font-semibold leading-[48px] text-zelene-beige transition-transform hover:text-zelene-dark-blue'
              >
                {item.label}
              </Link>
              <Image src={RedStarSVG} alt='gg svg' width={24} height={24} className='!size-6 object-contain' />
            </div>
          )),
        )}
      </div>
    </div>
  );
};

export default AutoSlide;
