import { useRouter as useBaseRouter } from 'next/navigation';
import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';

export function useRouter() {
  const baseRouter = useBaseRouter();

  const router = useMemo(() => {
    const enhancedRouter = {
      ...baseRouter,
      push: async (...args: Parameters<typeof baseRouter.push>) => {
        NProgress.start();
        return baseRouter.push(...args);
      },
    };
    return enhancedRouter;
  }, [baseRouter]);

  useEffect(() => {
    return () => {
      NProgress.done();
    };
  }, []);

  return router;
}
