'use client';

import axiosClient from '@/helper/call-center';
import { scrollToTop } from '@/utils/srollToTop';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import Link from 'next/link';
import Consultation from './Consultation';
import PhoneItemFooter from './Footer/PhoneItemFooter';

const ContactSection = () => {
  const [form] = useForm();

  const createContactForm = useMutation({
    mutationFn: async (data: any) => {
      await axiosClient.post(`/contact`, data);
    },
    onSuccess: () => {
      notification.success({ message: 'Form sent!', description: 'Your message has been sent successfully' });
      form.resetFields();
      scrollToTop();
    },

    onError: (err: any) => {
      notification.error({ message: 'Error', description: `${err?.response?.data?.message}` });
    },
  });

  return (
    <section>
      <div className='relative flex flex-col gap-5 overflow-hidden bg-[linear-gradient(129.5deg,_#FFFDF9_55.01%,_#FFE189_95.27%)] pt-10'>
        <div className='absolute bottom-0 left-0 z-10 !h-[85%] w-[min(472px,65%)] bg-background-flower2 bg-contain bg-left bg-no-repeat'></div>

        <div className='absolute bottom-0 right-0 z-10 !h-[85%] w-[min(472px,65%)] bg-background-flower2 bg-contain bg-left bg-no-repeat'></div>

        <div className='z-20 mx-auto flex w-[90%] max-w-[1512px] flex-col sm:w-[80%]'>
          <div className='flex flex-col justify-between gap-10 sm:flex-row sm:gap-0'>
            {/* 1 */}
            <div className='flex flex-1 flex-col gap-4'>
              <div className='flex w-full items-center gap-4 font-[hneu] text-[20px] !leading-[24px] text-zelene-blue'>
                <div className='h-1 w-[93px] bg-[#1D3F75]'></div>
                <span className='text-[20px] font-medium'>Get Appointment</span>
              </div>

              <div className='w-full font-[wise] text-[clamp(2.2rem,1.7868rem_+_0.7547vw,2.5rem)] leading-[48px] text-zelene-blue sm:max-w-[452px]'>
                Book An Appointment Just For You
              </div>

              <div className='font-hneu text-base !text-zelene-dark-blue'>
                Please make an appointment in advance using{' '}
                <Link
                  // onClick={() => scrollToTop()}
                  className='font-semibold !text-zelene-dark-blue underline'
                  href={'/booking#top'}
                >
                  the link here
                </Link>
              </div>

              {/* <div className='flex w-full justify-center gap-4 pt-4 sm:justify-start'>
                <Image
                  alt='appStore'
                  width={130}
                  height={48}
                  src={AppStore}
                  className='h-[48px] w-[130px] cursor-pointer'
                />
                <Image
                  alt='ggplay'
                  width={130}
                  height={48}
                  src={GooglePlay}
                  className='h-[48px] w-[130px] cursor-pointer'
                />
              </div> */}
            </div>

            {/* 2 */}
            <Form
              layout='vertical'
              form={form}
              onFinish={vals => createContactForm.mutate(vals)}
              className='flex w-full max-w-[630px] flex-1 flex-col gap-6'
            >
              <div className='flex w-full items-center font-[hneu] text-[20px] !leading-[24px] text-zelene-blue'>
                <div className='h-1 w-[93px] bg-[#1D3F75]'></div>
                <span className='pl-2 text-[20px] font-medium'>Contact Form</span>
              </div>

              <div className='flex w-full flex-col gap-3 *:flex-1 *:!rounded-xl sm:flex-row'>
                <FormItem name={'name'} rules={[{ required: true, message: 'Please enter your  name' }]}>
                  <Input size='large' placeholder='Name' />
                </FormItem>
                <FormItem
                  name={'email'}
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter valid email' },
                  ]}
                >
                  <Input size='large' placeholder='Email' />
                </FormItem>
              </div>

              <div className='flex flex-col gap-3 *:flex-1 *:!rounded-xl sm:flex-row'>
                <PhoneItemFooter size={'large'} form={form} />

                <FormItem name={'description'}>
                  <Input size='large' placeholder='Comment' />
                </FormItem>
              </div>

              <Button
                onClick={() => form.submit()}
                size='large'
                type='primary'
                className='!rounded-xl !bg-[#224b8b] hover:!bg-[#4674C6]'
              >
                Send
              </Button>
            </Form>
          </div>

          <Consultation />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
