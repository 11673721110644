'use client';

import useMobileScreen from '@/hooks/useMobileScreen';
import { Carousel } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import PoliciesList1 from '../../../../public/images/Policies/PoliciesList1.webp';
import PoliciesList2 from '../../../../public/images/Policies/PoliciesList2.webp';
import PoliciesList3 from '../../../../public/images/Policies/PoliciesList3.webp';
import PoliciesList4 from '../../../../public/images/Policies/PoliciesList4.webp';

const LocationCarousel = () => {
  const isMobile = useMobileScreen();

  if (isMobile) {
    return (
      <div className='mt-16 h-[300px] w-[80%]'>
        <Carousel initialSlide={1} draggable infinite arrows dotPosition='bottom' slidesToScroll={1}>
          {[PoliciesList1, PoliciesList2, PoliciesList3, PoliciesList4].map((item, index) => {
            return (
              <div className='relative h-[300px] w-full' key={index}>
                <Image
                  src={item}
                  alt='Policies image'
                  fill
                  className='object-contain'
                  sizes='(min-width: 640px) 80vw,0vw'
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  }

  return (
    <div className='hidden w-full flex-col items-center gap-8 pt-16 sm:flex sm:flex-row'>
      {[PoliciesList1, PoliciesList2, PoliciesList3, PoliciesList4].map((item, index) => {
        return (
          <div
            className={clsx(
              'relative h-[507px] w-[80%] sm:w-full',
              index % 2 === 0 && 'sm:h-[345px]',
              (index === 0 || index === 3) && 'sm:w-[70%]',
            )}
            key={index}
          >
            <Image
              src={item}
              alt='Policies image'
              className={clsx(
                'w-full rounded-[44px] object-cover object-center',
                index === 0 && 'sm:rounded-bl-none sm:rounded-tl-none',
                index === 3 && 'sm:rounded-br-none sm:rounded-tr-none',
              )}
              fill
              sizes='(min-width: 640px) 80vw,50vw'
            />
          </div>
        );
      })}
    </div>
  );
};

export default LocationCarousel;
