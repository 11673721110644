'use client';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRouter } from 'next/navigation';

const BookNowBtn = () => {
  const router = useRouter();
  return (
    <Button
      onClick={() => router.push('/booking')}
      size='large'
      icon={<ArrowRightOutlined />}
      styles={{ icon: { order: 2 } }}
      className='!z-[70] w-fit cursor-pointer !rounded-xl !bg-[linear-gradient(180deg,_#102647_0%,_#1D3F75_100%)] !text-white hover:!bg-[#4674C6]'
    >
      Book Now
    </Button>
  );
};

export default BookNowBtn;
