'use client';

import Image from 'next/image';
import ImageSlider2 from '../../../../../../public/images/Home/Slider2.webp';
import Background from '../../../../../../public/images/Home/Zelene-Bg-Text.webp';
import Pattern from '../../../../../../public/images/Pattern.png';
import WhiteStar from '../../../../../../public/images/WhiteStar.png';
import BuyNowBtn from './BuyNowBtn';

const Slider2 = () => {
  return (
    <div className='relative h-[600px] bg-[#FFF4E4] sm:h-[700px] md:h-[982px]'>
      <div className='absolute !left-0 bottom-0 !z-50 hidden h-[200px] w-[80%] opacity-80 sm:bottom-8 sm:block md:h-[228px] md:w-[55%]'>
        <Image fill alt='bg text' className='z-30 object-contain' src={Background} />
      </div>

      <div className='flex h-full w-full justify-between'>
        <div className='relative !z-30 hidden flex-[7] bg-zelene-cream pt-[113px] md:flex lg:pt-[144px]'>
          <div className='absolute left-[15%] top-[212px] !z-30 flex w-full flex-col items-center justify-center md:left-1/2 lg:left-1/4'>
            <ContentSlider2 />
          </div>
        </div>

        {/* Right section */}
        <div className='relative z-10 mt-[45px] flex h-full w-full flex-[8] justify-start rounded-bl-[44px] rounded-tl-[44px] sm:h-full sm:!flex-[10] lg:!flex-[7]'>
          <Image src={ImageSlider2} alt='slider2' className='h-full w-full' fill sizes='80vw' />

          <div className='left-1/4 top-[30%] !z-30 flex w-full flex-col items-center justify-center md:hidden'>
            <div className='z-30 flex flex-col font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue'>
              <span className='flex text-zelene-dark-blue'>
                Giftcard is{' '}
                <Image
                  src={WhiteStar}
                  alt='background image'
                  width={70}
                  height={70}
                  className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
                />
              </span>

              <div className='z-30 -mt-8 flex w-full items-center gap-6'>
                <Image
                  src={Pattern}
                  alt='background image'
                  width={113}
                  height={113}
                  className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[113px]'
                />

                <span className='font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue sm:text-zelene-dark-blue'>
                  Available
                </span>
              </div>

              <div className='max-w-[300px] pb-6 font-hneu text-base leading-[21.6px] text-zelene-dark-blue sm:text-[18px] sm:text-gray-800'>
                Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift
                card.
              </div>

              <BuyNowBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider2;

const ContentSlider2 = () => {
  return (
    <div className='z-50 flex flex-col font-[wise] font-medium text-zelene-dark-blue'>
      <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
        Giftcard is
      </div>

      <div className='z-30 flex w-full items-center gap-6 lg:-mt-10'>
        <Image
          src={Pattern}
          alt='background image'
          width={113}
          height={113}
          className='!size-[40px] object-contain font-[wise] text-[128px] sm:!size-[60px] lg:!size-[113px]'
        />

        <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
          Available
        </div>

        <Image
          src={WhiteStar}
          alt='background image'
          width={70}
          height={70}
          className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
        />
      </div>

      <div className='max-w-[300px] pb-6 pt-3 font-hneu text-base leading-[21.6px] text-zelene-slight-blue'>
        Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.
      </div>

      <BuyNowBtn />
    </div>
  );
};
