import { defaultHeadPhone, numberPrefixOptions } from '@/utils/phone';
import { Select, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Input from 'antd/es/input/Input';
import React from 'react';

interface IPhoneItemFooterProps {
  form: any;
  size?: any;
}

const PhoneItemFooter = (props: IPhoneItemFooterProps) => {
  const { size = 'middle', form } = props;
  const [head, setHead] = React.useState(defaultHeadPhone);
  const [phoneNumber, setPhoneNumber] = React.useState('');

  // Update the form value manually
  const handleHeadChange = (val: string) => {
    setHead(val);
    form.setFieldsValue({ phone: `${val}${phoneNumber}` });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);
    form.setFieldsValue({ phone: `${head}${value}` });
  };

  // Use React useEffect to keep form value updated
  React.useEffect(() => {
    if (head && phoneNumber) {
      form.setFieldsValue({ phone: `${head}${phoneNumber}` });
    }
  }, [head, phoneNumber, form]);

  return (
    <FormItem
      name={'phone_number'}
      rules={[
        { required: true, message: 'Please enter your phone' },
        {
          validator: (_, value) => {
            const phonePattern = /^[0-9]{10,15}$/; // Example pattern for 10-15 digits
            if (!value || phonePattern.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter a valid phone number.'));
          },
        },
      ]}
      className='!w-full'
    >
      <Space direction='vertical' className='!flex !w-full !max-w-full !flex-1'>
        <Space.Compact style={{ width: '100%' }} className='!flex !w-full !max-w-full !flex-1'>
          <Select
            size={size}
            style={{ width: '30%' }}
            options={numberPrefixOptions}
            defaultValue={defaultHeadPhone}
            onChange={handleHeadChange}
          />

          <Input
            className='!w-[70%] !flex-1'
            onChange={handlePhoneNumberChange}
            size={size}
            placeholder='Enter your phone'
          />
        </Space.Compact>
      </Space>

      {/* <div>123</div> */}
    </FormItem>
  );
};

export default PhoneItemFooter;
